@font-face {
    font-family: "Major Mono Display";
    font-display: swap;
    src: local("Major Mono Display"),
      url(./fonts/MajorMonoDisplay-Regular.ttf) format("truetype");
  }

  @font-face {
    font-family: "Knewave";
    font-display: swap;
    src: local("Knewave"),
      url(./fonts/Knewave-Regular.ttf) format("truetype");
  }
  
  @font-face {
    font-family: "Noto Sans";
    font-display: swap;
    src: local("Noto Sans"),
      url(./fonts/NotoSans-Regular.ttf) format("truetype");
  }
  